/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable react/jsx-filename-extension */

/* eslint-disable @typescript-eslint/no-floating-promises */
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { WholePageLoader } from '../components/layout/PageLoader';

const IndexPage = () => {
  useEffect(() => {
    const storageLang = localStorage.getItem('lang');
    const lang = storageLang !== null ? `/${storageLang}` : '/pl';
    navigate(lang);
  }, []);

  return <WholePageLoader />;
};

export default IndexPage;
